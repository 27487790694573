<template>
  <div id="app">
    <ribbon></ribbon>
    <div id="app-header">
      <jhi-navbar></jhi-navbar>
    </div>
    <div class="container-fluid">
      <div class="card jh-card">
        <router-view></router-view>
      </div>
      <b-modal id="login-page" hide-footer lazy style="border-radius: 20px !important">
        <span data-cy="loginTitle" slot="modal-title" id="login-title">로그인</span>
        <login-form></login-form>
      </b-modal>

      <jhi-footer></jhi-footer>
    </div>
  </div>
</template>
<style scoped>
.modal-content {
  border-radius: 20px;
}
</style>

<script lang="ts" src="./app.component.ts"></script>
